import {createSlice} from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'app',
    initialState: {
        appDeleted: {},
        showDeleteConfirmation: false,
        showWaitListForm: true,
    },
    reducers: {
        toggleAppDeleted(state, action) {
            const { appDeleted } = action.payload;
            state.appDeleted = appDeleted;
        },
        toggleDeleteConfirmation(state, action) {
            const { showDeleteConfirmation } = action.payload;
            state.showDeleteConfirmation = showDeleteConfirmation;
        },
        toggleWaitListForm(state, action) {
            const { showWaitListForm } = action.payload;
            state.showWaitListForm = showWaitListForm;
        }
    },
});

export const {
    toggleAppDeleted,
    toggleDeleteConfirmation,
    toggleWaitListForm,
} = appSlice.actions;
export default appSlice.reducer;
